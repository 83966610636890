const LOGO_PATH = (window.__DOZ_SSR_PATH__ ? '' : window.location.origin) + '/logo/';

exports.metadataChannelsUrl = 'https://meta1.xdevel.com/song/group/gds';
exports.metadataProgramUrl = 'https://meta1.xdevel.com/program/current/antenna-dello-stretto';
exports.defaultStation = '/antenna-dello-stretto';
exports.stations = {
    '/antenna-dello-stretto': {
        name: 'Antenna dello Stretto',
        type: 'audio',
        channel: {
            sources: [
                {
                    source: 'https://onair20.xdevel.com/proxy/antennadellostretto?mp=/;',
                    mimeType: 'audio/mpeg'
                }
            ],
            hideNative: true,
            hideAfterSpot: true,
            //vastUrl: 'https://pubads.g.doubleclick.net/gampad/ads?sz=640x480&iu=/124319096/external/single_ad_samples&ciu_szs=300x250&impl=s&gdfp_req=1&env=vp&output=vast&unviewed_position_start=1&cust_params=deployment%3Ddevsite%26sample_ct%3Dlinear&correlator='
        },
        metaUrl: 'https://meta1.xdevel.com/song/current/antenna-dello-stretto',
        logo: LOGO_PATH + 'antenna-dello-stretto.svg',
        defaultCover: LOGO_PATH + 'antenna-dello-stretto-cover-white.svg',
        defaultArtist: 'Antenna Dello Stretto',
        defaultTitle: ' ',
        active: true,
        description: ''
    },
    '/rgs': {
        name: 'RGS',
        type: 'audio',
        channel: {
            sources: [
                {
                    source: 'https://onair15.xdevel.com/proxy/rgs?mp=/;',
                    mimeType: 'audio/mpeg'
                }
            ],
            hideNative: true,
            hideAfterSpot: true,
            //vastUrl: 'https://pubads.g.doubleclick.net/gampad/ads?sz=640x480&iu=/124319096/external/single_ad_samples&ciu_szs=300x250&impl=s&gdfp_req=1&env=vp&output=vast&unviewed_position_start=1&cust_params=deployment%3Ddevsite%26sample_ct%3Dlinear&correlator='
            //vastUrl: 'https://tpc.googlesyndication.com/ima3vpaid?vad_format=linear&correlator=&adtagurl=https://pubads.g.doubleclick.net/gampad/ads?sz=640x480&description_url=%5bplaceholder%5d&vpos=preroll&iu=/117645302/VIDEO_GDS_1&env=vp&gdfp_req=1&output=vast&tfcd=0&npa=0&vpmute=0&vpa=0&type=js&unviewed_position_start=1'
        },
        metaUrl: 'https://meta1.xdevel.com/song/current/rgs',
        //programUrl: 'http://gds.it/utility/get-palinsesto.php?channel=rgs',
        //programUrl: 'http://meta1.xdevel.com/program/current/radio-subasio',
        logo: LOGO_PATH + 'rgs.svg',
        defaultCover: LOGO_PATH + 'rgs-cover-white.svg',
        defaultArtist: 'RGS',
        defaultTitle: 'Radio Giornale Di Sicilia',
        active: true,
        description: ''
    },
    '/rtp': {
        name: 'RTP',
        type: 'video',
        channel: {
            sources: [
                {
                    source: 'https://router.xdevel.com/video0s975966-797/stream/playlist.m3u8'
                }
            ],
            //vastUrl: 'https://pubads.g.doubleclick.net/gampad/ads?sz=640x480&iu=/124319096/external/single_ad_samples&ciu_szs=300x250&impl=s&gdfp_req=1&env=vp&output=vast&unviewed_position_start=1&cust_params=deployment%3Ddevsite%26sample_ct%3Dlinear&correlator='
        },
        metaUrl: 'https://meta1.xdevel.com/song/current/rtp',
        logo: LOGO_PATH + 'rtp.svg',
        defaultCover: LOGO_PATH + 'rtp-cover-white.svg',
        defaultArtist: 'RTP',
        defaultTitle: 'Radio Televisione Peloritana',
        active: true,
        description: ''
    },
    '/tgs': {
        name: 'TGS',
        type: 'video',
        channel: {
            sources: [
                {
                    source: 'http://flash8.streaming.xdevel.com/taorminatv/taorminatv/playlist.m3u8'
                }
            ],
            //vastUrl: 'https://pubads.g.doubleclick.net/gampad/ads?sz=640x480&iu=/124319096/external/single_ad_samples&ciu_szs=300x250&impl=s&gdfp_req=1&env=vp&output=vast&unviewed_position_start=1&cust_params=deployment%3Ddevsite%26sample_ct%3Dskippablelinear&correlator='
        },
        metaUrl: 'https://meta1.xdevel.com/song/current/tgs',
        programUrl: 'https://gds.it/utility/get-palinsesto.php?channel=tgs',
        logo: LOGO_PATH + 'tgs.svg',
        defaultCover: LOGO_PATH + 'tgs-cover-white.svg',
        defaultArtist: 'TGS',
        defaultTitle: '',
        active: false,
        description: ''
    }
};

exports.API_XDEVEL = {
    clientId: 'f786cc830ae0d2f3311dbe0a86802cde102fab24',
    key: 'bf8c4550984584518974e87846e5436bbf272dd0'
};