import {component} from 'doz'

component('x-onair', {

    store: 'onAir',

    props: {
        programUrl: '',
        programImage: '',
        programTitle: '',
        show: false
    },

    template(h) {
        return h`
            <style> 
            
                :root {
                    display: block;
                    overflow: hidden;
                    height: ${this.props.show ? 120 : 0}px;
                    transition: all 300ms;
                    background: #ffffff;
                }
                
                :root:hover img {
                    -webkit-filter: contrast(180%);
                    filter: contrast(180%);
                }
                
                h2 {
                    color: red;
                    font-size: 16px;
                }
                
                img {
                    margin-right: 20px;
                    float: left;
                    height: 120px;
                    transition: all 300ms;
                }
                
                a:link {
                    text-decoration: none;
                }
                                
                .programInfo {
                    text-align: left;
                }

                .programTitle {
                    color: #000;
                    font-size: 14px;
                }
            </style>
            <a href="${this.props.programUrl}">
                <img src="${this.props.programImage}">
                <div class="programInfo">
                    <h2>Ora in onda</h2>
                    <h1 class="programTitle">${this.props.programTitle}</h1>
                </div>
                <x-clear/>
            </a>
        `
    }
});