import {component} from 'doz'
import './channel'
import './onair'

component('x-sidebar', {

    store: 'sidebar',

    onCreate() {

        this.props = {
            channels: {
                audio: [],
                video: []
            },
            show: true
        };

        this.propsListenerAsync = {
            show(v) {
                this.getStore('playerInterface').expand = !v;
                this.getStore('player').expand = !v;
                this.getStore('backdrop').show = v && window.innerWidth < 700;
                this.getStore('burgerVideo').showClose = this.getStore('playerInterface').hidden && !v;

                this.emit('show', v);
                setTimeout(()=>{
                    this.emit('afterShow', v);
                }, 500);
            }
        };

        const stations = this.shared.config.stations;

        Object.keys(stations).forEach(key => {
            if (!stations[key].active) return;
            this.props.channels[
                stations[key].type
                ].push(Object.assign({key}, stations[key]));
        });

    },

    template(h) {
        return h` 
            <style> 
                :root {
                    position: fixed;
                    right: ${this.props.show ? 0 : '-340px'};
                    top: 0;
                    display: block;
                    height: 100%;
                    width: 300px;
                    box-shadow: 0 0 20px rgba(0,0,0,.5);
                    overflow-y: auto;
                    overflow-x: hidden;
                    user-select: none;
                    background: #19314c;
                    z-index: 1;
                    transition: all 500ms;
                }
                
                h3 {
                    color: #f2be1e;
                }

                ul{
                    margin: 0;
                    padding: 0;
                }
                
                li{
                    list-style: none;
                    margin: 0;
                    padding: 0;
                    font-size: 12px;
                }
                
                .button-close-container {
                    text-align: left;
                    padding: 10px;
                }
                
                .button-close-container button {
                    background: transparent;
                    color: #fff;
                    border: none;
                    font-size: 24px;
                    cursor: pointer;
                    animation-duration: 500ms!important;
                }
            </style>
            
            <div class="button-close-container">
                <button onclick="this.props.show = false" class="animated ${this.props.show ? 'fadeInLeft': 'fadeOutLeft'}"> 
                    <i class="fa fa-bars"></i>
                </button>
            </div>
            <x-onair/>
            <h3>Radio</h3>
            <ul>
                ${this.each(this.props.channels.audio, item => `
                    <li 
                        d-is="x-channel" 
                        d:store="${item.key}" 
                        track-image="${item.defaultCover}" 
                        track-artist="${item.defaultArtist}" 
                        track-title="${item.defaultTitle}" 
                        channel-url="${item.key}" 
                        channel-name="${item.name}"> 
                    </li>
                `)}
            </ul>   
            <h3>TV</h3>
            <ul>
                ${this.each(this.props.channels.video, item => `
                    <li 
                        d-is="x-channel" 
                        d:store="${item.key}" 
                        track-image="${item.defaultCover}" 
                        track-artist="${item.defaultArtist}" 
                        track-title="${item.defaultTitle}" 
                        channel-url="${item.key}" 
                        channel-name="${item.name}"> 
                    </li>                
                `)}
            </ul>    
        `
    }

});