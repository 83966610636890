import {component} from 'doz'

component('x-artwork-background', {
    template(h) {
        return h` 
            <style> 
                bg-img {
                    position: absolute;
                    height: 100%;
                    width: 100%;
                    top: 0;
                    left: 0;
                    background-size: cover;
                    background-repeat: no-repeat;
                    filter: blur(10px);
                    webkit-filter: blur(10px);
                    opacity: 0.3;
                }
            </style>
            <bg-img style="background-image: url('${this.props.url}')"/>
        `
    }
});