import stringme from 'stringme'
import hashtagfy from 'hashtagfy2'
import slash from 'normalize-slashes'
import axios from 'axios'

function parseUrl(url) {
    const el = document.createElement('a');
    el.href = url;
    return el;
}

export default {
    queryShareSocial(platform, win, e) {
        if (e) {
            e.stopPropagation();
        }

        const uri = parseUrl(window.location.href);
        const host = slash(uri.host);
        const baseUrl = `${uri.protocol}//${host}${uri.pathname}`;
        const path = slash(`${uri.pathname}${uri.hash}`, {start: true});

        const data = this.getStore('playerInterface');

        const meta = {
            path,
            title: data.channelName,
            description: `${data.channelName} suona ${data.trackArtist} - ${data.trackTitle}`,
            hashTag: hashtagfy(data.channelName),
            imageUrl: data.trackImage
        };

        const longUrl = `${baseUrl}?meta=${encodeURIComponent(btoa(stringme(meta)))}`;
        //this.shared.shortner.exec(longUrl)
        axios.post(`https://api.xdevel.com/shortlinks/create?clientId=${this.shared.config.API_XDEVEL.clientId}&key=${this.shared.config.API_XDEVEL.key}`, {url: longUrl})
            .then(response => {
                //const win = window.open('', '_blank');
                const v = response.data.result.shortUrl;
                switch (platform) {
                    default:
                    case 'facebook':
                        win.location.href = `https://www.facebook.com/sharer.php?u=${v}&hashtag=${encodeURIComponent(meta.hashTag)}`;
                        break;
                    case 'twitter':
                        win.location.href = `https://twitter.com/share?url=${v}&text=${encodeURIComponent(meta.hashTag + ' ' + meta.description)}`;
                        break;
                    case 'googleplus':
                        win.location.href = `https://plus.google.com/share?url=${v}`;
                        break;
                }

            })
            .catch(e => {
            throw e
        });
    }

}