import {component} from 'doz'

component('x-channel', {

    props: {
        channelName: '',
        channelUrl: '',
        trackImage: '',
        trackArtist: '',
        trackTitle: ''
    },

    template(h) {
        return h`
            <style> 
                .inner a:link, .inner a:visited{
                    color: white;
                    text-decoration: none;
                }
                
                .inner a {
                    display: block;
                    padding: 20px;
                    background: #165075;
                    margin-bottom: 10px;
                }
                
                .inner a:hover{
                    background: #2196f33b;
                }
                
                :global a.router-link-active{
                    background: #921725!important;
                }
                
                img {
                    float: left;
                    width: 80px;
                    margin-right: 20px;
                    border-radius: 6px;
                }
                
                .trackInfo {
                    float: left;
                    text-align: left;
                    width: 140px;
                    height: 80px;
                    display: flex;
                    flex-flow: row wrap;
                    align-content: center;
                }
                
                .trackArtist {
                    width: 100%;
                    margin-bottom: 5px;
                    font-size: 14px;
                }
                
                .trackTitle {
                    width: 100%;
                }
                
                h2 {
                    margin-top: 0;
                    margin-bottom: 10px;
                    text-align: left;
                    margin-left: 20px;
                    font-size: 14px;
                }
            </style>
           
            <div class="inner">
                <h2>${this.props.channelName}</h2>
                <a data-router-link="true" href="${this.props.channelUrl}">
                    <img src="${this.props.trackImage}">
                    <div class="trackInfo"> 
                        <div class="trackArtist">${this.props.trackArtist}</div>
                        <div class="trackTitle">${this.props.trackTitle}</div>
                    </div>
                    <x-clear/>
                </a>
            </div>
        `
    }

});
