import {component} from 'doz'

component('burger-video', {

    store: 'burgerVideo',
    props: {
        showClose: false
    },
    template(h) {
        return h`
            <style> 
                button {
                    position: absolute;
                    z-index: 10;
                    background: transparent;
                    color: #fff;
                    border: none;
                    font-size: 24px;
                    cursor: pointer;
                    margin: 10px;
                    top: 0;
                    right: 0;
                    animation-duration: 500ms!important;
                }
            </style>
            
            <button 
                class="animated ${this.props.showClose ? 'fadeInRight': 'fadeOutRight'}"
                onclick="(this.getStore('sidebar').show = true)">
                <i class="fa fa-bars"></i>
            </button>
        `
    }

});