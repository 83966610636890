import Doz from 'doz'
import 'doz-router'
import 'animate.css'
import './app.css'
import './cmp/sidebar'
import './cmp/sidebar/backdrop'
import './cmp/controller'
import './cmp/clear'
import './cmp/overlay'
import './cmp/burger-video'
import metaTag from 'doz-metatag'
import {updateChannelsMetadata} from './metadata'
import hotLocationReload from 'doz-hot-location-reload'
import config from '../config'
//import Shortner from 'shortner-fe'
import helper from './helper'
import animate from 'animatify'
import touchwipe from 'vanilla-touchwipe'

hotLocationReload(module);


if (!window.__DOZ_SSR_PATH__) {
    const fontAwesomeLink = document.createElement('link');
    fontAwesomeLink.rel = 'stylesheet';
    fontAwesomeLink.href = '/font-awesome-4.7.0/css/font-awesome.min.css';
    document.head.appendChild(fontAwesomeLink);
}

Doz.use(metaTag);
Doz.mixin(helper);

new Doz({

    shared: {
        animate,
        playerInstance: null,
        currentStationKey: null,
        config/*,
        shortner: window.__DOZ_SSR_PATH__
            ? null
            : new Shortner('AIzaSyDT7ZULH9vasJQEvTUYM_M9dCt6zcj29pQ')*/
    },

    root: '#app',

    template(h) {
        return h`
        <x-sidebar/>
        <burger-video/>
        <main>
            <doz-router mode="history" initial-redirect="${config.defaultStation}">
                <x-controller route="/*" preserve></x-controller>
            </doz-router>
        </main>
        <x-backdrop/>
    `
    },

    onMountAsync() {
        const sidebarProps = this.getStore('sidebar');
        let documentTitle = 'Gazzetta Del Sud - GDS | player';

        const currentPath = window.__DOZ_SSR_PATH__;

        if (currentPath) {
            const currentStation = config.stations[currentPath];
            if (currentStation) {
                documentTitle = currentStation.name;
            }

            this.metaTag({
                title: documentTitle
            });
        }

        function isScreenMinor() {
            return window.innerWidth >= 1024;
        }

        sidebarProps.show = isScreenMinor();

        window.addEventListener('resize', () => {
            sidebarProps.show = isScreenMinor();
        });

        touchwipe(this.getHTMLElement(), {
            wipeLeft() {
                sidebarProps.show = true;
            },
            wipeRight() {
                sidebarProps.show = false;
            },
            preventDefaultEvents: false
        })
    },

    onAppReady() {
        updateChannelsMetadata(this.app);
    }

});