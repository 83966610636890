import {component} from 'doz'

component('x-volume', {

    onCreate() {
        this._lastValue = 50;
        this.props = {
            value: 0,
            _showSlider: false,
            sliderTime: 2000
        };
    },

    onUpdate() {
        // hack
        this.ref.slider.value = this.props.value;
        if (this.props.value)
            this._lastValue = this.props.value;
    },

    onMount() {
        const container = this.getHTMLElement();
        let timer = null;

        container.addEventListener('mouseover', () => {
            this.props._showSlider = true;
            if (timer) {
                clearTimeout(timer);
                timer = null;
            }
        });

        container.addEventListener('mouseleave', () => {
            timer = setTimeout(() => {
                this.props._showSlider = false;
            }, this.props.sliderTime);
        });

        document.body.addEventListener('click', e => {
            if (this.ref.slider === e.target || this.ref.button === e.target) return;
            this.props._showSlider = false;
        });
    },

    template: function (h) {
        return h`

            <style> 
                :root {
                    position: relative;
                }
                
                x-slider-wrapper {
                    display: inline-block;
                    position: absolute;
                    top: 2px;
                    left: 40px;
                }
                
                .slider {
                    width: 70px;
                    margin: 0;
                }
                
                button {
                    background: none;
                    border: none;
                    font-size: 24px;
                    color: #fff;
                    width: 44px;
                    color: #19314c;
                }
                
                
            </style>
            
            <button onclick="this.toggle()">
                <i d-ref="button" class="fa fa-volume-${this.props.value <= 0 ? 'off' : 'up'}"></i>
            </button>
            <x-slider-wrapper style="display: ${this.props._showSlider ? 'inline-block' : 'none'}">
                <input class="slider" d-ref="slider" onchange="this.setVolume()" type="range" min="0" max="100" step="10" value="${this.props.value}">
            </x-slider-wrapper>
            
        `;
    },

    toggle() {
        const value = this.ref.button.classList.contains('fa-volume-up') ? 0 : this._lastValue;
        this.emit('change', value);
    },

    setVolume(e) {
        this.emit('change', e.target.value);
    }

});