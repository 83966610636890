import {component} from 'doz'

component('x-share', {

    template(h) {
        return h`
            <style>
                button {
                    background: none;
                    border: none;
                    font-size: 24px;
                    width: 44px;
                    color: #19314c;
                }
            </style>
            
            <x-share-inner>
                <button forceupdate onclick="this.shareClick()"><i class="fa fa-share-alt"></i></button>
            </x-share-inner>
        `
    },

    shareClick() {
        this.app.mount(`
            <style> 
                button {
                    background: none;
                    border: none;
                }
                
                .overlay-content {
                    display: flex;
                    justify-content: center;
                    height: 100%;
                    flex-flow: row wrap;
                    align-content: center;
                    color: #fff;
                }
                
                .overlay-content h2 {
                    width: 100%;
                }
                
                .overlay-content button {
                    font-size: 44px;
                    color: #fff;
                    margin: 10px;
                }
            </style>
            <x-overlay> 
                <div class="overlay-content">
                    <h2>Condividi su</h2>
                    <button onclick="const win = window.open('', '_blank'); this.queryShareSocial('facebook', win)">
                        <span class="fa fa-facebook-official"></span>
                    </button>
                    <button onclick="const win = window.open('', '_blank'); this.queryShareSocial('twitter', win)">
                        <span class="fa fa-twitter"></span>
                    </button>
                </div>
            </x-overlay>
        `);
    }
});