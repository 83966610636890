import {component} from 'doz'
import loadim from 'loadim'

component('x-artwork', {

    store: 'artwork',

    props: {
        pulse: false
    },

    propsListener: {
        url: function (v, o) {
            loadim(v, () => {
                this.shared.animate(this.ref.img, 'flipInX', {duration: '1000ms'})
            });
        }
    },

    template(h) {
        return h`
            <style> 
                .pulse {
                    animation: pulse 1.5s infinite;
                    border-radius: 50%;
                }
                
                img {
                    width: 400px;
                    height: 400px;
                    box-shadow: 0 0 10px rgba(0,0,0,.5);
                    border: 3px solid #fff;
                    border-radius: 10px;
                }

                @keyframes pulse {
                    0% {
                        box-shadow: 0 0 0 0 rgba(25,49,76, 0.4);
                    }
                    70% {
                        box-shadow: 0 0 20px 100px rgba(25,49,76, 0);
                    }
                    100% {
                        box-shadow: 0 0 0 0 rgba(25,49,76, 0);
                    }
                }
                
                @media only screen and (max-device-width: 600px) {
                    img {
                        width: 250px;
                        height: 250px;
                    }
                }
                
                @media only screen and (max-device-width: 384px) {
                    img {
                        width: 200px;
                        height: 200px;
                    }
                }
                
                @media only screen and (max-device-width: 320px) {
                    img {
                        width: 150px;
                        height: 150px;
                    }
                }
            </style> 
            
            <div class="${this.props.pulse ? 'pulse' : ''}">
                <img d-ref="img" src="${this.props.url}">
            </div>
        `
    }
});