import jsonp from 'jsonp'
import capitalize from 'titleize'

let timeoutProgram;

function prettify(str, isTitle) {
    str = str.trim();
    if (!/^(RTP|RGS)$/.test(str))
        return capitalize(str);

    if (str === 'RGS' && isTitle)
        str = 'Radio Giornale Di Sicilia';

    return str;
}

export function updateChannelsMetadata(app) {
    if (window.__DOZ_SSR_PATH__) return;

    const url = app.shared.config.metadataChannelsUrl;
    const stations = app.shared.config.stations;

    jsonp(`${url}?${Math.random()}`, {name: 'cb_channels'}, (err, data) => {
        if (!err) {

            const result = data.result;
            const playerUI = app.getStore('playerInterface');

            Object.keys(result).forEach(key => {
                const fullKey = '/' + key;
                const store = app.getStore(fullKey);
                if (!store) return;

                store.trackImage = result[key].artworkLarge.trim() || stations[fullKey].defaultCover;
                store.trackArtist = prettify(result[key].artist) || stations[fullKey].defaultArtist;
                store.trackTitle = prettify(result[key].title, true) || stations[fullKey].defaultTitle;

                // Set current channel info
                if (app.shared.currentStationKey === fullKey) {
                    playerUI.channelImage = stations[fullKey].logo;
                    playerUI.channelName = stations[fullKey].name;
                    playerUI.trackImage = store.trackImage;
                    playerUI.trackArtist = store.trackArtist;
                    playerUI.trackTitle = store.trackTitle;
                }

            });
        }

        setTimeout(() => updateChannelsMetadata(app), 10000)
    });
}

export function updateProgramMetadata(app) {
    if (window.__DOZ_SSR_PATH__) return;

    const url = app.shared.currentStation.programUrl;

    if (!url) {
        clearTimeout(timeoutProgram);
        return;
    }

    jsonp(url, {name: 'cb_program'}, (err, data) => {
        if (!err) {
            const result = data.result;
            const onAir = app.getStore('onAir');

            onAir.programUrl = result.link;
            onAir.programImage = result.image;
            onAir.programTitle = result.title;
        }

        timeoutProgram = setTimeout(() => updateProgramMetadata(app), 10000)

    });

}