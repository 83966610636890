import {component} from 'doz'

component('x-track',  {
    template(h) {
        return h`
            
            <style> 
                :root {
                    margin-top: 40px;
                    margin-bottom: 40px;
                    display: block;
                    width: 100%;
                    color: #19314c;
                }
                
                h2 {
                    font-size: 40px;
                }
                
                h3 {
                    font-size: 26px;
                }
                
                h2, h3 {
                    margin-top: 0;
                    margin-bottom: 15px;
                    white-space: nowrap; 
                    overflow: hidden;
                    text-overflow: ellipsis;
                    padding: 0 20px;
                }
                
                @media only screen and (max-width: 600px) {
                    h2 {
                        font-size: 30px;
                    }
                
                    h3 {
                        display: block;
                        font-size: 20px;
                    }
                }
                
                @media only screen and (max-width: 320px) {
                    h2 {
                        font-size: 20px;
                    }
                
                    h3 {
                        font-size: 15px;
                    }
                }
            </style>
            
            <h2>${this.props.trackArtist}</h2>
            <h3>${this.props.trackTitle}</h3>
        `
    }
});