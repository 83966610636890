import {component} from 'doz'
const Player = window.__DOZ_SSR_PATH__ ? '' : require("x-player-wrapper");

component('x-player', {

    id: 'player',

    store: 'player',

    props: {
        expand: false
    },

    onCreate() {

        const events = {
            onVisible: visible => {
                this.getStore('playerInterface').hidden = visible
            },
            onBuffering: () => {
                this.getStore('playerInterface').playbackState = 'loading';
            },
            onPlay: () => {
                this.getStore('artwork').pulse = true;
                this.getStore('playerInterface').playbackState = 'play';
            },
            onStop: () => {
                this.getStore('artwork').pulse = false;
                this.getStore('playerInterface').playbackState = 'stop';
            },
            onVolumeUpdate: v => {
                this.getStore('playerInterface').playbackVolume = v;
            }
        };

        this.createPlayer = channel => {

            if (window.__DOZ_SSR_PATH__) return;

            if (!this.shared.playerInstance) {
                // Create player instance
                new Player(this.ref.playerContainer, channel, events)
                    .then(instance => {
                        this.shared.playerInstance = instance;
                        this.onDrawPlayer();
                    })
                    .catch(e => {
                        throw e;
                    });
            } else {
                // Switch channel
                this.shared.playerInstance.setConfig(channel);
                this.onDrawPlayer();
            }

        }
    },

    onDrawPlayer() {
        const key = this.shared.currentStationKey;
        const current = this.getStore(key);
        const playerInterface = this.getStore('playerInterface');
        const onAir = this.getStore('onAir');

        // Update player with current channel info
        playerInterface.channelImage = this.shared.currentStation.logo;
        playerInterface.channelName = this.shared.currentStation.name;
        playerInterface.trackImage = current.trackImage;
        playerInterface.trackArtist = current.trackArtist;
        playerInterface.trackTitle = current.trackTitle;
        playerInterface.playbackVolume = this.shared.playerInstance.player.getVolume();

        onAir.show = !!this.shared.currentStation.programUrl;

    },

    template(h) {
        return h`
            
            <style> 
                .playerContainer {
                    position: absolute; 
                    height: 100%; 
                    width: ${this.props.expand ? '100%' : 'calc(100% - 300px)'}; 
                    left: 0; 
                    top: 0;
                    transition: all 500ms;
                    z-index: 1;
                }
            </style>
            
            <div 
                class="playerContainer"
                d-ref="playerContainer" 
            />
        `
    }
});