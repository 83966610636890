import {component} from 'doz'

component('x-play-stop', {

    props: {
        state: 'stop'
    },

    template: function () {
        let out = `
            <style> 
                button {
                    background: transparent;
                    border: none;
                    font-size: 54px;
                    width: 70px;
                    height: 70px;
                    padding: 10px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin: 0 20px;
                    color: #19314c;
                }
            </style>
        `;

        switch (this.props.state) {
            case 'play':
                out += `<button forceupdate onclick="this.emit('playclick')"><i class="fa fa-pause"></i></button>`;
                break;
            default:
            case 'stop':
                out += `<button forceupdate onclick="this.emit('stopclick')"><i class="fa fa-play"></i></button>`;
                break;
            case 'loading':
                out += `<button forceupdate disabled="true"><i class="fa fa-circle-o-notch fa-spin fa-fw"></i></button>`;
        }

        return out;
    }
});