import {component} from "doz";

component('x-backdrop', {

    store: 'backdrop',

    props: {
        show: false
    },

    template() {
        return `
            <div>
                <style>
                    :root {
                        display: ${this.props.show ? 'block' : 'none'};
                        position: fixed;
                        background: rgba(0,0,0,.8);
                        width: 100%;
                        height: 100%;
                        left: 0;
                        top: 0;
                        z-index: 0;
                    }
                </style>
            </div>
        `
    },

    onMountAsync() {
        this.getHTMLElement().addEventListener('click', () => {
            this.getStore('sidebar').show = false;
        });
    }
});