import {component} from 'doz'

component('x-clear', {

    template(h) {
        return h`
            <div style="clear: both"></div>
        `
    }

});