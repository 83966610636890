import {component} from 'doz'

component('x-overlay', {
    template() {
        return `
            <style>
                :root {
                    position: fixed;
                    width: 100%;
                    height: 100%;
                    left: 0;
                    top: 0;
                    background-color: rgba(0,0,0,0.8);
                    z-index: 99999;
                    padding: 0;
                    margin: 0;
                }
            </style>
        `;
    },

    onMount() {
        const rootElement = this.getHTMLElement();
        const opt = {
            duration: '300ms'
        };
        this.shared.animate(rootElement, 'slideInUp', opt);
        // Add to tag container
        rootElement
            .addEventListener('click', () => {
                this.shared.animate(rootElement, 'slideOutUp', opt, () => {
                    rootElement.style.display = 'none';
                    this.remove();
                });
            });
    },

    remove() {
        // If is mounted
        if (this.parent && this.parent.tag === 'dz-mount')
            setTimeout(() => {
                this.parent.destroy();
            });
        else
            this.destroy();

    }
});