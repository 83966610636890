import {component} from 'doz'
import {updateProgramMetadata} from "../metadata";
import './player'
import './player-interface'

component('x-controller', {

    template(h) {
        return h`
            <x-player/>
            <x-player-interface/>
        `
    },

    onMountAsync() {
        this.draw();
    },

    onAfterRender() {
        this.draw();
    },

    draw() {
        const player = this.getCmp('player');
        if (!player) return;
        const key = this.router.currentPath().split('?')[0];
        const station = this.shared.config.stations[key];

        if(this.getStore('backdrop').show) {
            this.getStore('sidebar').show = false;
        }

        if (station && !window.__DOZ_SSR_PATH__) {
            this.shared.currentStationKey = key;
            this.shared.currentStation = station;
            player.createPlayer(station.channel);
            updateProgramMetadata(this.app);
            this.metaTag({
                title: this.shared.currentStation.name
            });
        }
    }

});