import {component} from 'doz'

component('x-header', {

    props: {
        showClose: false
    },

    template(h) {
        return h` 
 
            <style> 
                :root {
                    position: relative;
                    display: block;
                    text-align: left;
                    height: 100px;
                    overflow: hidden;
                }
                
                img {
                    height: 50px;
                    float: left;
                    margin: 25px;
                }
                
                button {
                    float: right;
                    background: transparent;
                    color: #19314c;
                    border: none;
                    font-size: 24px;
                    cursor: pointer;
                    margin: 10px;
                    animation-duration: 500ms!important;
                }
            </style>
            
            <img src="${this.props.logoUrl}" title="${this.props.title}" alt="${this.props.title}">
            <button 
                class="animated ${this.props.showClose ? 'fadeInRight': 'fadeOutRight'}"
                onclick="(this.getStore('sidebar').show = true)">
                <i class="fa fa-bars"></i>
            </button>
            <x-clear/>
            
        `
    }

});