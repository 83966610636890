import {component} from 'doz'
import './volume'
import './play-stop'
import './artwork'
import './artwork-background'
import './track'
import './header'
import './share'

component('x-player-interface', {
    store: 'playerInterface',
    props: {
        hidden: true,
        channelImage: '',
        channelName: '',
        playbackState: 'stop',
        playbackVolume: 100,
        trackArtist: '',
        trackTitle: '',
        trackImage: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mP8Xw8AAoMBgDTD2qgAAAAASUVORK5CYII=',
        expand: false
    },

    template(h) {
        return h`

            <style> 
                :root {
                    display: block;
                    position: absolute;
                    left: 0;
                    top: 0;
                    width: ${this.props.expand ? '100%' : 'calc(100% - 300px)'};
                    height: 100%;
                    overflow: auto;
                    overflow-x: hidden;
                    transition: all 500ms;
                }
                
                .inner {
                    position: absolute;
                    height: 100%;
                    width: 100%;
                    left: 0;
                    top: 0;
                }
                
                .track-info {
                    position: relative;
                    height: calc(100% - 104px);
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    flex-flow: wrap;
                    align-self: flex-start;
                }
                
                .controls {
                    display: flex;
                    align-items: center;
                    align-self: center;
                    justify-content: center;
                    margin-bottom: 40px;
                }
                
                button {
                    cursor: pointer;
                }
                
                .made-by {
                    font-size: 10px;
                    display: block;
                    width: 100%;
                    text-align: center;
                    padding-bottom: 5px;
                }
                
                .made-by:link{
                    color: #666;
                    text-decoration: none;
                }
                
                .made-by:visited{
                    color: #666;
                }
                
                .made-by:hover{
                    color: #000;
                }
            </style>
            
            <div class="inner" style="display: ${this.props.hidden ? 'none' : 'block'};"> 
                <x-artwork-background
                    url="${this.props.trackImage}"
                />
                <x-header 
                    logo-url="${this.props.channelImage}" 
                    show-close="${this.props.expand}"
                    title="${this.props.channelName}"
                />
                <div class="track-info">
                    <x-artwork 
                        url="${this.props.trackImage}" 
                    />
                    <x-track
                        trackArtist="${this.props.trackArtist}"
                        trackTitle="${this.props.trackTitle}"
                    />
                    <div class="controls">
                        <x-share/>
                        <x-play-stop 
                            state="${this.props.playbackState}"
                            d:on-playclick="playClick"
                            d:on-stopclick="stopClick"
                        />
                        <x-volume
                            value="${this.props.playbackVolume}"
                            d:on-change="volumeChange"
                        />
                    </div>
                    <a class="made-by" target="_blank" href="https://www.xdevel.com">Made with ❤ by Xdevel</a>
                </div>
                
            </div>
        `
    },

    playClick() {
        this.app.shared.playerInstance.player.stop()
    },

    stopClick() {
        this.app.shared.playerInstance.player.play()
    },

    volumeChange(v) {
        this.app.shared.playerInstance.player.setVolume(v)
    }

});